<template>
  <div class="container-fluid px-0">
    <div class="row pt-1">
      <div class="col-lg-12">
        <h3 class="mb-2 text-center">{{ setTitle($route.query.type) }} Company Instructions</h3>
      </div>
      <div class="col-lg-5">
        <form class="form-inline small" @submit.prevent="">
          <input class="form-control form-control-sm w-75" type="search"
                 placeholder="Search Instruction Description ..."
                 aria-label="Search Instruction Description ..." v-model="filters.search">
          <button class="e-btn e-btn-outlined-green e-text-hover-white-light-1" type="submit"
                  style="margin-left: -29px; padding-top: 0.35rem; padding-bottom: 0.35rem;">
            <font-awesome-icon class="e-text-green" icon="search"/>
          </button>
        </form>
      </div>
      <div class="col-lg-7 align-items-end">
        <nav aria-label="...">
          <ul class="pagination justify-content-end text-smallest mb-2 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setPage(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item"
                :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setPage(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <template v-if="!loading">
        <company-instruction-tree  @sendResponse="resetByVesselResponse"
                                   @resetCompanyInstruction="setPage"
                                  :company_instructions="companyInstructions">
        </company-instruction-tree>
    </template>
    <template v-else>
      <app-loading></app-loading>
    </template>
  </div>
</template>
<script>
import {PaginationMixin} from "@/mixins/PaginationMixin";
import {mapActions, mapGetters} from "vuex";
import _ from 'lodash';
import CompanyInstructionTree from "../tree/CompanyInstructionTree.vue";
import AppLoading from "../../../elements/AppLoading.vue";
export default {
  name: "MasterCompanyInstructionPerModule",
  components: {CompanyInstructionTree, AppLoading},
  mixins:[PaginationMixin],
  data(){
    return{
      loading: false,
      filters: {
        type:this.$route.query.type,
        page: 1,
        perPage:10,
        search: '',
        sentOnly:true,
      },
      controller : null,
      isActive: true,
    }
  },
  computed:{
    ...mapGetters(['companyInstructions'])
  },
  methods: {
    ...mapActions(['getCompanyInstructions','getJobStatuses', 'getAcknowledgementStatuses',]),
    async setPage(page = null){
      if (this.isActive === false) return;
      this.controller = new AbortController();
      this.loading=true;
      this.filters.page = page ?? 1
      await this.getCompanyInstructions({...this.filters, signal : this.controller.signal})
      this.loading=false;
    },

    async resetByVesselResponse() {
      this.loading = true;
      await this.getCompanyInstructions(this.filters)
      this.loading = false;
    },
    initializeWatcher(){
      this.$watch(()=>this.$route.query,async (present,previous)=>{
        this.filters.type=present?.type ?? 'air'
        this.filters.search=''
        await this.setPage()
      });
    },

    setTitle(type){
      let title=null;
      switch (type){
        case 'air':
          title='Accident Incident'
          break;
        case 'psc':
          title="PSC (Port State Control) Inspection"
          break;
        case 'rs':
          title="Rightship Inspection"
          break;
        case 'ia_oe':
          title="Vessel Audit"
          break;
        default:
          title='Accident Incident'
          break;
      }
      return title;
    }

  },
  async created(){
    this.loadIns=_.debounce(this.setPage,750)
    await this.getJobStatuses()
    await this.getAcknowledgementStatuses()
    await this.setPage();
    this.initializeWatcher()
  },
  beforeDestroy() {
    this.isActive = false;
    if (this.controller) {
      this.controller.abort();
    }
  },
  mounted() {
    $(document).on('shown.bs.modal', function (e) {
      $(e.target).before($(e.target).find('.modal-backdrop').clone().css('z-index', $(e.target).css('z-index')-1));
      $(e.target).find('.modal-backdrop').removeClass('in').css('transition', 'none');
    });

    $(document).on('hide.bs.modal', function (e) {
      $(e.target).prev('.modal-backdrop').remove();
    });
  },
  watch:{
    "filters.search"(){
      this.loadIns()
    }
  }
}
</script>

<style scoped>

</style>
