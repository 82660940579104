<template>
  <master-company-instruction-per-module
    :key="$route.query.type"
  />
</template>
<script>
import MasterCompanyInstructionPerModule
  from "@/components/modules/company-instruction/master/MasterCompanyInstructionPerModule.vue";
export default {
  name: "MasterCompanyInstruction",
  components : {MasterCompanyInstructionPerModule}
}
</script>

<style scoped>

</style>
